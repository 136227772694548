import React from 'react'
import { Typography } from '@material-ui/core'

import Page from '../../components/page'
import Stepper from '../../components/stepper'
import withRoot from '../../withRoot'

import publish from "../../static/publish/publish.png"
import publish1 from "../../static/publish/publish-1.png"
import publish2 from "../../static/publish/publish-2.png"
import publish3 from "../../static/publish/publish-3.png"
import publish4 from "../../static/publish/publish-4.png"

import { InfoNote, WarningNote } from '../../components/highlight'

const STEPS = [
  {
    label: 'Test your design on a real device',
    content: 'Sometimes there are differences between the editor and the result on a real watch, you must test your work to be sure everything is okay.',
    img: '',
  },
  {
    label: 'Select the design you want to publish',
    content: 'Open the panel of the design you want to publish and click on the \'Publish to gallery\' button.',
    img: publish,
  },
  {
    label: 'Enter the description',
    content: 'A good description is always cool, please describe the main features of your design.',
    img: publish1,
  },
  {
    label: 'Set the price',
    content: 'If you want to publish a paid clockface, just set the price you want or leave it at 0 for a free one.',
    img: publish2,
  },
  {
    label: '(Optional) Allow us to publish your work in the Fitbit gallery',
    content: 'You can authorize us to publish your design in the Fitbit gallery.',
    img: publish3,
  },
  {
    label: 'Tick the copyrights checkbox',
    content: 'By ticking this box, you ensure you have all the necessary rights to publish the design (images, fonts, ...). Obvious copyright infringements will be removed.',
    img: publish4,
  },
  {
    label: 'Click the Publish button',
    content: 'Congrats, your design is now live in the FitFace gallery!',
    img: '',
  },
  {
    label: 'Sit back & relax',
    content: 'Every month we will send your payout if you make sales.',
    img: '',
  },
]

const Publish = (props) => {
  return (
    <Page {...props} title="How to publish a design?">
      <Typography>
        By default, all your designs are private but you probably want to share them worldwide.<br />
        Please follow the instructions below if you&apos;re interested.
      </Typography>
      <Stepper steps={STEPS} />
      <WarningNote>If you make some changes in a published design, you will need to re-publish it to make them available to everyone.</WarningNote>
      <InfoNote>You can unpublish your design by clicking on Publish to gallery and setting the Publish to gallery switch to off.</InfoNote>
    </Page>
  )
}

export default withRoot(Publish)
